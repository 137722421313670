.portfolioName {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-gray-900);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  line-height: 1.5rem;
}

.statusIcon {
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.smallText {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-block-start: 0.25rem;
  margin-inline-start: 1.5rem;

  font-weight: 500;
  color: var(--color-gray-500);

  & > span {
    display: flex;
    align-items: center;
  }

  // tiny circles
  & > span:not(:first-child)::before {
    content: "";
    height: 0.125rem;
    width: 0.125rem;
    background: var(--color-gray-500);
    display: inline-block;
    margin-inline: 0.5rem;
  }
}

.viewErrorsBtn {
  color: inherit;
  padding: 0 !important;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1;
  height: auto;
}

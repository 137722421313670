.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.skeletonWrapper {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  opacity: 0.35;
  filter: blur(1px);
  overflow: hidden;
}

.wrapper {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 128px;
  border: 1px solid #e7e7e7;
  background: #f9fafb;
  border-radius: 12px;
  padding: 32px;
  box-shadow:
    rgba(50, 50, 93, 0.05) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.06) 0px 1px 6px -1px;
}

.iconWrapper {
  height: 64px;
  width: 64px;
  background: #eee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 32px;
  color: #333;

  :global(svg) {
    height: 24px;
    width: 24px;
  }
}

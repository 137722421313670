.loadingWrapper {
  min-height: 500px;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-block: 1rem 0.5rem;
}

.searchInput {
  max-width: 400px;
}

.searchTotal {
  color: var(--color-gray-500);
}

.listContainer {
  position: relative;
  overflow-x: scroll;
}

.list {
  margin-block: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--color-gray-100);
  min-width: 640px;
}

.listItem {
  padding-inline: 16px !important;
  border: 0 !important;
  &:nth-child(odd) {
    background: var(--color-gray-50);
  }
}

.processingAlert {
  border: none;
  margin-top: 16px;
}

.processingAlertTitle {
  font-size: 0.9rem;
  font-weight: 500;
  color: #0369a1;
}

.processingAlertDescription {
  font-size: 0.85rem;
  color: #0369a1;
}

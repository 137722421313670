.fileUploadContainer {
  --color-alert-error: #dc2626;
  --color-alert-warning: #ca8a04;

  display: grid;
  row-gap: 1em;
}

.fileContainer {
  padding: 0.5em;
  border-radius: 0.5em;
  border: 2px solid var(--color-gray-100);
  margin: 0;

  :global(.ant-upload-list-item) {
    background: var(--color-gray-100);
    padding: 1em;
    height: auto !important;
    border-radius: 0.5em;
  }

  :global(.ant-upload-list-item:first-child) {
    margin: 0 !important;
  }

  :global(legend) {
    padding: 0 1em;
  }
}

.errorIcon {
  margin-inline-end: 4px;
}

.fileInfoContainer {
  padding: 1em;
  border-radius: 0.5em;
  border: 2px solid var(--color-gray-100);
}

.fileInfoName {
  grid-column: span 2;
  height: 28px;
  margin-left: -12px;
  width: calc(100% + 12px);
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
}

.statsContainer {
  display: grid;
  padding: 0.5em 1em;
  padding-top: 0;
}

.statsRowContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: 32px;

  .statsTitle {
    font-weight: 600;
  }
}

.statsValue {
  :global(.ant-statistic-content),
  :global(.ant-statistic-content-value),
  :global(.ant-statistic-content-value-int) {
    font-size: 1rem !important;
    color: inherit;
  }

  &.warning {
    color: var(--color-alert-warning);
    font-weight: 600;
  }

  &.error {
    color: var(--color-alert-error);
    font-weight: 600;
  }
}

.alert {
  align-items: flex-start;
  margin-bottom: 1em;
  border: none;

  :global(.anticon) {
    margin-top: 3px;
  }

  :global(a) {
    text-decoration: underline;
  }

  &:global(.ant-alert-error) {
    color: var(--color-alert-error);

    :global(a) {
      color: var(--color-alert-error);
    }
  }

  &:global(.ant-alert-warning) {
    color: var(--color-alert-warning);

    :global(a) {
      color: var(--color-alert-warning);
    }
  }
}

.progressContainer {
  --color-light: #f0abfc;
  --color-dark: #c026d3;
  :global(.ant-progress-bg) {
    animation: progress 2s ease-in-out infinite;
  }

  &.error {
    --color-light: #ef4444;
    --color-dark: #991b1b;
    :global(.ant-progress-success-bg) {
      animation: progress 2s ease-in-out infinite;
    }
  }
}

@keyframes progress {
  0% {
    background-color: var(--color-light);
  }

  50% {
    background-color: var(--color-dark);
  }

  100% {
    background-color: var(--color-light);
  }
}
